import { useState, useEffect } from 'react';
import { Select } from 'antd';

import ScheduleFilterSlideover from '../components/ScheduleFilterSlideover';
import AnnouncementsTab from '../components/AnnouncementsTab';
import ExhibitorsTab from '../components/ExhibitorsTab';
import ScheduleTab from '../components/ScheduleTab';
import useSchedule from '../hooks/useSchedule';
import MapTab from '../components/MapTab';
import Tag from '../components/Tag';

export default function HomeScreen({ viewName }) {
  const [activeKey, setActiveKey] = useState(localStorage.getItem('lastTab') || 'schedule');
  const [dayFilter, setDayFilter] = useState(sessionStorage.getItem('lastDate') || '2024-05-25');
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const { getDays } = useSchedule();

  const [selectedCategories, setSelectedCategories] = useState(
    sessionStorage.getItem('selectedCategories') ? JSON.parse(sessionStorage.getItem('selectedCategories')) : null,
  );

  const [selectedSessionTracks, setSelectedSessionTracks] = useState(
    sessionStorage.getItem('selectedSessionTracks')
      ? JSON.parse(sessionStorage.getItem('selectedSessionTracks'))
      : null,
  );

  useEffect(() => {
    localStorage.setItem('lastTab', activeKey);
  }, [activeKey]);

  useEffect(() => {
    sessionStorage.setItem('lastDate', dayFilter);
  }, [dayFilter]);

  useEffect(() => {
    sessionStorage.setItem('selectedCategories', JSON.stringify(selectedCategories));
  }, [selectedCategories]);

  useEffect(() => {
    sessionStorage.setItem('selectedSessionTracks', JSON.stringify(selectedSessionTracks));
  }, [selectedSessionTracks]);

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  return (
    <>
      <header className="header_home">
        <div className="header_home__title">
          <h1>2024 CBA Conference</h1>
        </div>

        <div className="header_home__select_tab">
          <div>
            <Select
              className="select_tab__select"
              style={{ width: '100%' }}
              size="large"
              defaultValue={activeKey}
              onChange={(e) => handleTabChange(e)}
              options={[
                { value: 'schedule', label: 'Schedule', className: 'text-xl' },
                { value: 'announcements', label: 'Announcements', className: 'text-xl' },
                { value: 'exhibitors', label: 'Exhibitors', className: 'text-xl' },
                { value: 'map', label: 'Map', className: 'text-xl' },
              ]}
            />
          </div>
        </div>
      </header>

      {activeKey === 'schedule' && getDays() && (
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            padding: '0px 10px',
          }}
        >
          <div className="days-filter" style={{ marginTop: '50px', marginBottom: '10px' }}>
            {getDays().format.map((item, index) => (
              <div onClick={() => setDayFilter(item)} className={item === dayFilter && 'days-filter--selected'}>
                {getDays().days[index]}
              </div>
            ))}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              cursor: 'pointer',
              marginTop: 30,
              paddingRight: 10,
            }}
            onClick={() => setIsOpenFilters(true)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                stroke="#475467"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      )}

      {activeKey === 'schedule' && (
        <div className="filter-items" style={{ marginTop: 10 }}>
          {selectedCategories &&
            selectedCategories.length > 0 &&
            selectedCategories.map((item) => (
              <Tag
                closable
                onClose={() => setSelectedCategories(selectedCategories.filter((category) => category !== item))}
              >
                {item}
              </Tag>
            ))}
          {selectedSessionTracks &&
            selectedSessionTracks.length > 0 &&
            selectedSessionTracks.map((item) => (
              <Tag
                closable
                onClose={() =>
                  setSelectedSessionTracks(selectedSessionTracks.filter((sessionTrack) => sessionTrack !== item))
                }
              >
                {item}
              </Tag>
            ))}
        </div>
      )}

      {activeKey === 'schedule' && (
        <ScheduleTab
          viewName={viewName}
          dayFilter={dayFilter}
          categoryFilter={selectedCategories}
          selectedCategories={selectedCategories}
          sessionTrackFilter={selectedSessionTracks}
        />
      )}
      {activeKey === 'announcements' && <AnnouncementsTab />}
      {activeKey === 'exhibitors' && (
        <div style={{ marginTop: 20 }}>
          <ExhibitorsTab />
        </div>
      )}
      {activeKey === 'map' && <MapTab />}

      <ScheduleFilterSlideover
        isOpen={isOpenFilters}
        selectedSessionTracks={selectedSessionTracks}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        setSelectedSessionTracks={setSelectedSessionTracks}
        onClose={() => setIsOpenFilters(false)}
      ></ScheduleFilterSlideover>
    </>
  );
}
