import { Result, Button, Empty } from 'antd';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import useSchedule from '../hooks/useSchedule';
import SessionCard from './SessionCard';
import Loader from './Loader';
import { modifiedMomentjs } from '../utils/modifiedMomentjs';

export default function ScheduleTab({ dayFilter, sessionTrackFilter, categoryFilter, viewName }) {
  const { getScheduleAirtable, getScheduleDates, getEventsByDay, getEventsByStartTime } = useSchedule();
  const { isLoading, data, error } = useSelector((state) => state.schedule);

  useEffect(() => {
    if (data.length === 0) getScheduleAirtable(viewName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Result
        title="Error request"
        extra={
          <Button type="primary" onClick={() => getScheduleAirtable()}>
            Try again
          </Button>
        }
      />
    );
  }

  if (data.length > 0) {
    return (
      <div className="home-wrapper">
        {getScheduleDates(dayFilter).map(({ day, start }, index) => (
          <div>
            {getEventsByDay(day, sessionTrackFilter, categoryFilter).map(
              ({ title, start, end, id, category, location, mapLink, sessionTrack, hidden }, index) => {
                if (!title || title === '' || title === undefined || title === null || hidden) return null;
                else {
                  return (
                    <div className="container-schedule-items">
                      <div style={{ marginBottom: 4 }}>{modifiedMomentjs(start, 'h:mm A')}</div>

                      {getEventsByStartTime(start, sessionTrackFilter, categoryFilter).map(
                        ({ title, start, end, id, category, location, mapLink, sessionTrack }, index) => {
                          if (!title || hidden) return null;
                          else
                            return (
                              <SessionCard
                                key={index}
                                title={title}
                                start={start}
                                end={end}
                                id={id}
                                category={category}
                                location={location}
                                mapLink={mapLink}
                                sessionTrack={sessionTrack}
                              />
                            );
                        },
                      )}
                    </div>
                  );
                }
              },
            )}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="space-section">
      <Empty />
    </div>
  );
}
