import { Result, Button, Empty, Card, Typography, Divider } from 'antd';
import useAnnouncements from '../hooks/useAnnouncements';
import useExhibitors from '../hooks/useExhibitors';
import { PushpinFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Loader from './Loader';
import { modifiedMomentjs } from '../utils/modifiedMomentjs';

const { Paragraph } = Typography;
const { Meta } = Card;

export default function AnnouncementsTab() {
  const { isLoading, data, error } = useSelector((state) => state.announcements);
  const { getAnnouncementsAirtable } = useAnnouncements();
  const { getExhibitorsAirtable } = useExhibitors();
  const navigate = useNavigate();

  useEffect(() => {
    getExhibitorsAirtable();
    getAnnouncementsAirtable();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Result
        title="Error request"
        extra={
          <Button type="primary" onClick={() => getAnnouncementsAirtable()}>
            Try again
          </Button>
        }
      />
    );
  }

  if (data.length > 0) {
    return (
      <div style={{ paddingBottom: 24, paddingTop: 24 }}>
        {data.map((announcement) => {
          return (
            <div style={{ margin: '22px' }}>
              <Card
                hoverable
                bordered
                style={{ marginBottom: '4%' }}
                onClick={() => navigate(`/announcement/${announcement.id}`)}
              >
                <div
                  style={{
                    width: 26,
                    height: 26,
                    background: '#950e19',
                    borderRadius: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: 12,
                  }}
                >
                  <PushpinFilled style={{ color: 'white' }} />
                </div>
                <Meta
                  title={announcement.title}
                  description={modifiedMomentjs(announcement.created, 'MMMM DD, YYYY')}
                  style={{ paddingBottom: '2%' }}
                />
                <Paragraph
                  style={{ margin: 0 }}
                  ellipsis={{
                    rows: 2,
                    expandable: false,
                    symbol: 'more',
                  }}
                >
                  {announcement.content}
                </Paragraph>
              </Card>

              <Divider style={{ border: '#ddd 1px solid' }} />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="space-section">
      <Empty />
    </div>
  );
}
