import { configureStore } from '@reduxjs/toolkit';

import exhibitorsSlice from './exhibitorsSlice';
import scheduleSlice from './scheduleSlice';
import speakersSlice from './speakersSlice';
import announcementsSlice from './announcementsSlice';

export const store = configureStore({
  reducer: {
    exhibitors: exhibitorsSlice,
    schedule: scheduleSlice,
    speakers: speakersSlice,
    announcements: announcementsSlice,
  },
});
