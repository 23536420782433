import { useDispatch, useSelector } from 'react-redux';
import { CBACSponsors } from '../airtable';
import { IsLoadingAnnouncements, addAnnouncements, addError } from '../store/announcementsSlice';

export default function useAnnouncements() {
  const { data } = useSelector((state) => state.announcements);
  const dispatch = useDispatch();

  const getAnnouncementsAirtable = () => {
    dispatch(IsLoadingAnnouncements(true));
    const data = [];

    CBACSponsors('Announcements')
      .select({
        view: '2024',
        sort: [{ field: 'Created', direction: 'desc' }],
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            const recordData = {
              id: record.id,
              title: record.get('Title'),
              content: record.get('Content'),
              created: record.get('Created'),
            };
            data.push(recordData);
          });

          fetchNextPage();
        },
        function done(err) {
          if (err) {
            dispatch(IsLoadingAnnouncements(false));
            dispatch(addError(err));
            return;
          }

          dispatch(addAnnouncements(data));
          dispatch(IsLoadingAnnouncements(false));
          return;
        },
      );
  };

  const getAnnouncementById = (id) => {
    return data.find((item) => item.id === id);
  };

  return { getAnnouncementsAirtable, getAnnouncementById };
}
