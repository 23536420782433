import SponsorContacts from '../components/SponsorContacts';
import { useParams, useNavigate } from 'react-router-dom';
import { SPONSOR_COLORS } from '../consts/SPONSOR_COLORS';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { CBACSponsors } from '../airtable';
import Loader from '../components/Loader';
import { Button, Result } from 'antd';

export default function SponsorScreen() {
  const [status, setStatus] = useState('loading');
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const container = useRef();

  const { id } = useParams();

  const getSponsor = useCallback(async (recordId) => {
    setStatus('loading');

    try {
      const record = await new Promise((resolve, reject) => {
        CBACSponsors('Sponsors/Exhibitors').find(recordId, (err, record) => {
          if (err) {
            reject(err);
          } else {
            resolve(record);
          }
        });
      });

      const recordData = {
        id: record.id,
        name: record.get('Sponsor/Exhibitor Name'),
        img: record.get('Logo'),
        sponsorshipTiers: record.get('Sponsorship Tiers'),
        website: record.get('Website'),
        booths: record.get('Booths (Plaintext)'),
        companyBio: record.get('Company Bio'),
        videoLink: record.get('Video Link'),
      };

      setData(recordData);
      setStatus('success');
    } catch (err) {
      console.log(err);
      setStatus('error');
    }
  }, []);

  useEffect(() => {
    getSponsor(id);
    window.scrollTo({ top: 0 });
  }, [getSponsor, id]);

  const sendMessage = () => {
    const height = container?.current?.scrollHeight;

    window.parent.postMessage({ height }, '*');
  };

  useEffect(() => {
    sendMessage();
    window.addEventListener('resize', sendMessage);
    return () => {
      window.removeEventListener('resize', sendMessage);
    };
  }, [data]);

  if (status === 'loading') return <Loader />;

  if (status === 'error')
    return (
      <Result
        title="Error request"
        extra={
          <Button type="primary" onClick={() => getSponsor(id)}>
            Try again
          </Button>
        }
      />
    );

  return (
    <div className="sponsor-screen" ref={container}>
      <header className="header_sponsor">
        <div className="header_sponsor__back">
          <h2>
            <span className="cursor-pointer">
              <ArrowLeftOutlined style={{ marginRight: 12 }} onClick={() => navigate('/')} />
            </span>
          </h2>
        </div>

        <div className="header_sponsor__info">
          <div style={{ marginTop: 16 }}>
            {data.img && <img src={data.img[0].url} style={{ maxWidth: 140 }} alt="Sponsor" />}
          </div>

          <div className="header_sponsor__info_sponsor">
            {data.sponsorshipTiers.map((item, key) => (
              <p key={key} style={{ color: SPONSOR_COLORS[item] }}>
                {item}
              </p>
            ))}
          </div>

          <div className="header_sponsor__info_name">
            <p>Booths {data?.name}</p>
          </div>

          <div className="header_sponsor__info_booth">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M8.00002 8.66666C9.10459 8.66666 10 7.77123 10 6.66666C10 5.56209 9.10459 4.66666 8.00002 4.66666C6.89545 4.66666 6.00002 5.56209 6.00002 6.66666C6.00002 7.77123 6.89545 8.66666 8.00002 8.66666Z"
                stroke="#475467"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.00002 14.6667C10.6667 12 13.3334 9.61218 13.3334 6.66666C13.3334 3.72114 10.9455 1.33333 8.00002 1.33333C5.0545 1.33333 2.66669 3.72114 2.66669 6.66666C2.66669 9.61218 5.33335 12 8.00002 14.6667Z"
                stroke="#475467"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p>Booth {data?.booths}</p>
          </div>
        </div>
      </header>

      <div className="description">
        <div className="description__title">Website</div>

        <div className="description__content">
          <a style={{ textDecoration: 'underline' }} rel="noreferrer" href={data?.website} target="_blank">
            {data?.website}
          </a>
        </div>
      </div>

      <div className="description">
        <div className="description__title">About</div>

        <div className="description__content">
          <p>{data?.companyBio || 'No registered.'}</p>
        </div>
      </div>

      <SponsorContacts sponsorName={data.name} />
    </div>
  );
}
