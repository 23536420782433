import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';

import { IsLoadingSchedule, addError, addSchedule } from '../store/scheduleSlice';
import { modifiedMomentjs } from '../utils/modifiedMomentjs';
import { CBACSponsors } from '../airtable';

const dayFormat = 'YYYY-MM-DD';

export default function useSchedule() {
  const { data } = useSelector((state) => state.schedule);
  const dispatch = useDispatch();

  const getScheduleAirtable = (viewName) => {
    dispatch(IsLoadingSchedule(true));
    const data = [];

    CBACSponsors('Schedule')
      .select({
        view: viewName,
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            const recordData = {
              id: record.id,
              events: record.get('Events'),
              title: record.get('Title'),
              day: record.get('Day'),
              start: record.get('Start'),
              end: record.get('End'),
              location: record.get('Location'),
              roomNames: record.get('Room Names'),
              notes: record.get('Notes'),
              description: record.get('Description'),
              speakers: record.get('Speakers'),
              sponsorsExhibitors: record.get('Sponsors/Exhibitors (from Speakers)'),
              category: record.get('Category'),
              mapLink: record.get('Map Link'),
              sessionTrack: record.get('Track'),
              hidden: record.get('Hidden from Schedule'),
            };

            if (recordData?.category !== 'Setup & Teardown' && recordData?.hidden !== true) {
              data.push(recordData);
            }
          });

          fetchNextPage();
        },
        function done(err) {
          if (err) {
            dispatch(IsLoadingSchedule(false));
            dispatch(addError(err));
            return;
          }

          dispatch(addSchedule(data));
          dispatch(IsLoadingSchedule(false));
          return;
        },
      );
  };

  const getEventById = (id) => {
    return data.find((item) => item.id === id);
  };

  const getScheduleDates = (dayFilter) => {
    let days = _.uniq(data, (data) => modifiedMomentjs(data.start, dayFormat));

    if (dayFilter) {
      days = days.filter((item) => modifiedMomentjs(item.start, dayFormat) === dayFilter);
    }

    return days;
  };

  const getEventsByDay = (day, sessionTrackFilter, categoryFilter) => {
    let filtered = data.filter((item) => modifiedMomentjs(item.start, dayFormat) === day);

    if (categoryFilter && categoryFilter.length > 0) {
      filtered = filtered.filter((item) => categoryFilter.includes(item.category));
    }

    if (sessionTrackFilter && sessionTrackFilter.length > 0) {
      filtered = filtered.filter((item) => sessionTrackFilter.includes(item.sessionTrack));
    }

    return _.uniq(filtered, (data) => data.start);
  };

  const getEventsByStartTime = (start, sessionTrackFilter, categoryFilter) => {
    let filtered = data.filter((item) => item.start === start);

    if (categoryFilter && categoryFilter.length > 0) {
      filtered = filtered.filter((item) => categoryFilter.includes(item.category));
    }

    if (sessionTrackFilter && sessionTrackFilter.length > 0) {
      filtered = filtered.filter((item) => sessionTrackFilter.includes(item.sessionTrack));
    }

    return filtered;
  };

  const getCategories = () => {
    const uniqueCategories = new Set();

    data.forEach((item) => {
      uniqueCategories.add(item.category);
    });

    return Array.from(uniqueCategories);
  };

  const getSessionTracks = () => {
    const uniqueCategories = new Set();

    data.forEach((item) => {
      if (item.sessionTrack) {
        uniqueCategories.add(item.sessionTrack);
      }
    });

    return Array.from(uniqueCategories).filter((category) => category);
  };

  const getDays = () => {
    let uniqueDataDays = _.uniq(data, (data) => modifiedMomentjs(data.start, dayFormat));
    let format = uniqueDataDays.map((item) => modifiedMomentjs(item.start, dayFormat));
    let days = uniqueDataDays.map((item) => modifiedMomentjs(item.start, 'MMM DD'));

    return { format, days };
  };

  return {
    getScheduleAirtable,
    getScheduleDates,
    getEventsByDay,
    getEventById,
    getCategories,
    getDays,
    getEventsByStartTime,
    getSessionTracks,
  };
}
