import ExhibitorsTab from '../components/ExhibitorsTab';
import React from 'react';

export default function ExhibitorsEmbedScreen() {
  return (
    <div className="exhibitor-embed">
      <p className="exhibitor-embed__title">Exhibitors</p>

      <ExhibitorsTab />
    </div>
  );
}
