import React from 'react';

export default function Tag({ children, closable, onClose }) {
  return (
    <div className="tag">
      {children}
      {closable && (
        <svg
          style={{ cursor: 'pointer' }}
          onClick={onClose}
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
        >
          <path
            d="M7.5 2.5L2.5 7.5M2.5 2.5L7.5 7.5"
            stroke="#98A2B3"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}{' '}
    </div>
  );
}
