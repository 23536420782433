import { motion, AnimatePresence } from 'framer-motion';
import { Button, Checkbox, Form } from 'antd';
import useSchedule from '../hooks/useSchedule';
import React, { useEffect } from 'react';
const { useForm } = Form;

export default function ScheduleFilterSlideover({
  isOpen,
  onClose,
  setSelectedCategories,
  selectedCategories,
  selectedSessionTracks,
  setSelectedSessionTracks,
}) {
  const { getCategories, getSessionTracks } = useSchedule();
  const [form] = useForm();

  const onFinish = (values) => {
    setSelectedCategories(values.categories);
    setSelectedSessionTracks(values.sessionTracks);
    onClose();
  };

  const handleReset = () => {
    setSelectedCategories(null);
    setSelectedSessionTracks(null);
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.setFieldValue('categories', selectedCategories);
    form.setFieldValue('sessionTracks', selectedSessionTracks);
  }, [form, selectedCategories, selectedSessionTracks]);

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ x: 400 }}
            animate={{ x: 0 }}
            exit={{ x: 400 }}
            transition={{ duration: 0.3 }}
            className="filter"
          >
            <Form onFinish={onFinish} form={form} style={{ paddingBottom: 70 }}>
              <div className="filter__header">
                <p>Filters</p>

                <svg
                  style={{ cursor: 'pointer' }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  onClick={onClose}
                >
                  <path
                    d="M15 5L5 15M5 5L15 15"
                    stroke="#98A2B3"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="filter__section">
                <div className="filter__section_title">
                  <p>Event Type</p>
                </div>

                <div className="filter__section_items">
                  <Form.Item name="categories">
                    <Checkbox.Group style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                      {getCategories().map((item, key) => (
                        <Checkbox key={key} value={item}>
                          {item}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </div>
              </div>

              <div className="filter__section">
                <div className="filter__section_title">
                  <p>Seminar Track</p>
                </div>

                <div className="filter__section_items">
                  <Form.Item name="sessionTracks">
                    <Checkbox.Group style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                      {getSessionTracks().map((item, key) => (
                        <Checkbox key={key} value={item}>
                          {item}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </div>
              </div>

              <div className="filter__actions">
                <div className="filter__actions_reset" onClick={() => handleReset()}>
                  Reset
                </div>

                <div className="filter__actions_buttons">
                  <Button htmlType="button" onClick={onClose}>
                    Cancel
                  </Button>

                  <Button type="primary" htmlType="submit">
                    Apply
                  </Button>
                </div>
              </div>
            </Form>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
