import { useDispatch, useSelector } from 'react-redux';
import { CBACSponsors } from '../airtable';
import { IsLoadingExhibitors, addError, addExhibitors } from '../store/exhibitorsSlice';

export default function useExhibitors() {
  const { data } = useSelector((state) => state.exhibitors);
  const dispatch = useDispatch();

  const getExhibitorsAirtable = () => {
    dispatch(IsLoadingExhibitors(true));
    const data = [];

    CBACSponsors('Sponsors/Exhibitors')
      .select({
        view: 'Sponsors & Exhibitors',
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            const recordData = {
              id: record.id,
              name: record.get('Sponsor/Exhibitor Name'),
              img: record.get('Logo'),
              sponsorshipTiers: record.get('Sponsorship Tiers'),
              website: record.get('Website'),
              booths: record.get('Booths'),
              boothNumbers: record.get('Booths (Plaintext)'),
            };
            data.push(recordData);
          });

          fetchNextPage();
        },
        function done(err) {
          if (err) {
            dispatch(IsLoadingExhibitors(false));
            dispatch(addError(err));
            return;
          }

          dispatch(addExhibitors(data));
          dispatch(IsLoadingExhibitors(false));
          return;
        },
      );
  };

  // Expects an array of booth record IDs (airtable)
  const getBooths = (boothRecordIds) => {
    let boothNumbers = [];

    // Get the booth numbers from the booth record IDs
    boothRecordIds.forEach((boothRecordId) => {
      CBACSponsors('Exhibition Booths').find(boothRecordId, function (err, record) {
        if (err) {
          console.error(err);
          return;
        }
        boothNumbers.push(record.get('Booth Number'));
      });
    });

    return String(boothNumbers);
  };

  const getSponsorsByTiers = (tier) => {
    return data.filter((item) => item?.sponsorshipTiers?.includes(tier));
  };

  return { getExhibitorsAirtable, getBooths, getSponsorsByTiers };
}
