import { ArrowLeftOutlined, UserOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';

import SessionsBySpeaker from '../components/SessionsBySpeaker';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Avatar, Button, Result } from 'antd';
import { CBACSponsors } from '../airtable';
import Loader from '../components/Loader';

export default function SpeakerScreen() {
  const navigate = useNavigate();
  const { speakerId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(null);
  const container = useRef();

  const getSpeakersAirtable = useCallback(async () => {
    setIsLoading(true);

    CBACSponsors('Contacts')
      .find(speakerId)
      .then((data) => {
        setData({
          fullName: data.get('Full Name'),
          bio: data.get('Bio'),
          pic: data.get('Pic'),
          title: data.get('Title/ Position'),
          company: data.get('Company'),
        });
        setIsError(false);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [speakerId]);

  useEffect(() => {
    getSpeakersAirtable();
  }, [getSpeakersAirtable]);

  const sendMessage = () => {
    const height = container?.current?.scrollHeight;

    console.log(height, 'here');

    window.parent.postMessage({ height }, '*');
  };

  useEffect(() => {
    sendMessage();
    window.addEventListener('resize', sendMessage);
    return () => {
      window.removeEventListener('resize', sendMessage);
    };
  }, [data]);

  if (isLoading) {
    return <Loader fullScreen />;
  }

  return (
    <div style={{ paddingBottom: 40 }} ref={container}>
      <header className="header_event">
        <div className="header_event__back">
          <h2>
            <span className="cursor-pointer">
              <ArrowLeftOutlined onClick={() => navigate(-1)} />
            </span>{' '}
          </h2>
        </div>

        <div className="header_event__information">
          <div className="speaker-card__img">
            {data?.pic ? (
              <img src={data.pic[0].url} alt="img" />
            ) : (
              <Avatar shape="circle" size={40} icon={<UserOutlined />} />
            )}
          </div>

          <div className="header_event__information_speaker_name">
            <p>{data && data?.fullName}</p>
          </div>

          <div className="header_event__information_speaker_info">
            <p>{data && data?.title}</p>
            <p>{data && data?.company}</p>
          </div>
        </div>
      </header>

      {isError && (
        <Result
          title="Error request"
          extra={
            <Button type="primary" onClick={() => getSpeakersAirtable()}>
              Try again
            </Button>
          }
        />
      )}

      {data && (
        <div className="description">
          <div className="description__title">About the Speaker</div>

          <div className="description__content">
            <p>{data.bio || 'No registered.'}</p>
          </div>
        </div>
      )}

      <SessionsBySpeaker id={speakerId} />
    </div>
  );
}
