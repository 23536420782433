import { Routes, Route } from 'react-router-dom';

import EventScreen from './screens/EventScreen';
import HomeScreen from './screens/HomeScreen';
import NotFoundScreen from './screens/NotFoundScreen';
import ExhibitorsEmbedScreen from './screens/ExhibitorsEmbedScreen';
import ScheduleEmbedScreen from './screens/ScheduleEmbedScreen';
import AnnouncementScreen from './screens/AnnouncementScreen';
import SponsorScreen from './screens/SponsorScreen';
import SpeakerScreen from './screens/SpeakerScreen';
import SpeakerEmbedScreen from './screens/SpeakerEmbedScreen';

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeScreen viewName="2024 CBAC Schedule" />} />
      <Route path="/schedule-embed" element={<ScheduleEmbedScreen viewName="2024 CBAC Schedule" />} />
      <Route path="/kombucha-schedule-embed" element={<ScheduleEmbedScreen viewName="Sessions Only" />} />
      <Route path="/wine-embed" element={<ScheduleEmbedScreen viewName="Wine" />} />
      <Route
        path="/distillers-schedule-embed"
        element={<ScheduleEmbedScreen viewName="Fermentation and Distillation" />}
      />

      <Route path="/:recordId" element={<EventScreen />} />
      <Route path="/:recordId/:speakerId" element={<SpeakerScreen />} />
      <Route path="/contact/:speakerId" element={<SpeakerScreen />} />
      <Route path="/announcement/:recordId" element={<AnnouncementScreen />} />
      <Route path="/not-found" element={<NotFoundScreen />} />
      <Route path="/exhibitors-embed" element={<ExhibitorsEmbedScreen />} />
      <Route path="/exhibitors-embed/sponsor/:id" element={<SponsorScreen />} />
      <Route path="/speaker-embed" element={<SpeakerEmbedScreen />} />
    </Routes>
  );
}

export default App;
