import { SPONSOR_COLORS } from '../consts/SPONSOR_COLORS';
import React from 'react';

export default function ExhibitorCard({ img, name, booths, sponsorshipTiers, onClick }) {
  const handleTag = (name) => {
    if (SPONSOR_COLORS[name])
      return (
        <div
          style={{
            borderTop: `2px solid #F2F4F7`,
            display: 'flex',
            position: 'absolute',
            top: '8px',
            width: '98%',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {name && (
            <div
              style={{
                backgroundColor: '#F2F4F7',
                padding: '6px',
                borderBottomLeftRadius: '4px',
                borderBottomRightRadius: '4px',
              }}
            >
              <p
                style={{
                  fontSize: '14px',
                  color: SPONSOR_COLORS[name],
                }}
              >
                {name}
              </p>
            </div>
          )}
        </div>
      );
  };

  return (
    <div className="exhibitor-card" onClick={onClick}>
      {handleTag(sponsorshipTiers)}

      <div className="exhibitor-card__img">
        <img src={img} alt={name} />
      </div>

      <div className="exhibitor-card__info">
        <div className="exhibitor-card__info_booths">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M8 8.66667C9.10457 8.66667 10 7.77124 10 6.66667C10 5.5621 9.10457 4.66667 8 4.66667C6.89543 4.66667 6 5.5621 6 6.66667C6 7.77124 6.89543 8.66667 8 8.66667Z"
              stroke="#667085"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 14.6667C10.6667 12 13.3333 9.61219 13.3333 6.66667C13.3333 3.72115 10.9455 1.33333 8 1.33333C5.05448 1.33333 2.66666 3.72115 2.66666 6.66667C2.66666 9.61219 5.33333 12 8 14.6667Z"
              stroke="#667085"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Booth {booths}
        </div>
        <div className="exhibitor-card__info_name">{name}</div>
      </div>
    </div>
  );
}
