import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CBACSponsors } from '../airtable';
import SpeakerCard from './SpeakerCard';
import { Skeleton } from 'antd';

export default function SponsorContacts({ sponsorName }) {
  const [status, setStatus] = useState('loading');
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const getContacts = useCallback(async (sponsorName) => {
    setStatus('loading');

    try {
      const records = await new Promise((resolve, reject) => {
        CBACSponsors('Contacts')
          .select({
            filterByFormula: `{Sponsors/Exhibitors} = "${sponsorName}"`,
          })
          .eachPage(
            (records, fetchNextPage) => {
              resolve(records);
              fetchNextPage();
            },
            (err) => {
              if (err) {
                reject(err);
              }
            },
          );
      });

      const recordData = records.map((record) => ({
        id: record.id,
        firstName: record.get('First Name'),
        lastName: record.get('Last Name'),
        fullName: record.get('Full Name'),
        pic: record.get('Pic'),
        bio: record.get('Bio'),
        events: record.get('Schedule'),
        title: record.get('Title/ Position'),
        company: record.get('Company'),
        description: record.get('Description'),
      }));

      setData(recordData);
      setStatus('success');
    } catch (err) {
      console.log(err);
      setStatus('error');
    }
  }, []);

  useEffect(() => {
    getContacts(sponsorName);
  }, [getContacts, sponsorName]);

  if (status === 'loading')
    return (
      <div className="speakers__avatars sponsor-contact">
        <div className="speakers__title">Contacts</div>

        <div className="speaker-card">
          <div className="speaker-card__img">
            <Skeleton.Avatar active={true} size={50} shape={'circle'} />
          </div>

          <div className="speaker-card__info">
            <div className="speaker-card__info_bio">
              <Skeleton paragraph={{ rows: 1 }} />
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="sponsor-contact">
      <div className="speakers__title">Contacts</div>

      {data.length === 0 ? (
        <div>
          <p>No speakers registered.</p>
        </div>
      ) : (
        <div className="speakers__avatars">
          {data.map((data) => (
            <SpeakerCard data={data} onClick={() => navigate(`/contact/${data.id}`)} />
          ))}
        </div>
      )}
    </div>
  );
}
