import { useCallback, useEffect, useState } from 'react';
import { Skeleton } from 'antd';

import { CBACSponsors } from '../airtable';
import SessionCard from './SessionCard';

export default function SessionsBySpeaker({ id }) {
  const [status, setStatus] = useState('loading');
  const [data, setData] = useState(null);

  const getSessions = useCallback(async (speakerId) => {
    setStatus('loading');

    try {
      const records = await new Promise((resolve, reject) => {
        CBACSponsors('Schedule')
          .select({
            view: '2024 CBAC Schedule',
          })
          .eachPage(
            (records, fetchNextPage) => {
              resolve(records);
              fetchNextPage();
            },
            (err) => {
              if (err) {
                reject(err);
              }
            },
          );
      });

      const recordData = records.map((record) => ({
        id: record.id,
        events: record.get('Events'),
        title: record.get('Title'),
        day: record.get('Day'),
        start: record.get('Start'),
        end: record.get('End'),
        location: record.get('Location'),
        roomNames: record.get('Room Names'),
        notes: record.get('Notes'),
        description: record.get('Description'),
        speakers: record.get('Speakers'),
        sponsorsExhibitors: record.get('Sponsors/Exhibitors (from Speakers)'),
        category: record.get('Category'),
        mapLink: record.get('Map Link'),
        sessionTrack: record.get('Track'),
        hidden: record.get('Hidden from Schedule'),
      }));

      const filtered = recordData.filter(
        (item) => item.speakers && item.speakers.length > 0 && item.speakers.includes(speakerId),
      );

      setData(filtered);
      setStatus('success');
    } catch (err) {
      console.log(err);
      setStatus('error');
    }
  }, []);

  useEffect(() => {
    getSessions(id);
  }, [getSessions, id]);

  if (status === 'loading') {
    return (
      <div style={{ margin: '30px 22px 0px 22px' }} className="speakers container-schedule-items">
        <Skeleton active />
      </div>
    );
  }

  if (data && data.length > 0) {
    return (
      <div className="speakers">
        <div className="speakers__title">Sessions</div>

        <div className="container-schedule-items">
          {data.map(({ title, start, end, id, category, location, mapLink, sessionTrack }, index) => (
            <SessionCard
              key={index}
              title={title}
              start={start}
              end={end}
              id={id}
              category={category}
              location={location}
              mapLink={mapLink}
              sessionTrack={sessionTrack}
            />
          ))}
        </div>
      </div>
    );
  }

  return <></>;
}
