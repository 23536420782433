import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  data: [],
};

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    IsLoadingSchedule: (state, action) => {
      state.isLoading = action.payload;
      state.error = null;
    },
    addSchedule: (state, action) => {
      state.data = action.payload;
    },
    addError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { IsLoadingSchedule, addSchedule, addError } = scheduleSlice.actions;

export default scheduleSlice.reducer;
