import { Button, Col, Result, Row } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CBACSponsors } from '../airtable';
import Loader from '../components/Loader';

export default function SpeakerEmbedScreen() {
  const [status, setStatus] = useState('loading');
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const container = useRef();

  const getSpeakers = useCallback(async () => {
    setStatus('loading');

    try {
      const records = await new Promise((resolve, reject) => {
        CBACSponsors('Contacts')
          .select({
            view: '2024 CBAC Speakers',
          })
          .eachPage(
            (records, fetchNextPage) => {
              resolve(records);
              fetchNextPage();
            },
            (err) => {
              if (err) {
                reject(err);
              }
            },
          );
      });

      const recordData = records.map((record) => ({
        id: record.id,
        fullName: record.get('Full Name'),
        title: record.get('Title/ Position'),
        company: record.get('Company'),
        pic: record.get('Pic'),
      }));

      const sortedData = recordData.sort((a, b) => {
        const nameA = a.fullName.replace(/\s/g, '');
        const nameB = b.fullName.replace(/\s/g, '');
        return nameA.localeCompare(nameB);
      });

      const filteredData = sortedData.filter((item) => item.pic);

      setData(filteredData);
      setStatus('success');
    } catch (err) {
      console.log(err);
      setStatus('error');
    }
  }, []);

  const sendMessage = () => {
    const height = container?.current?.scrollHeight;

    window.parent.postMessage({ height }, '*');
  };

  useEffect(() => {
    sendMessage();
    window.addEventListener('resize', sendMessage);
    return () => {
      window.removeEventListener('resize', sendMessage);
    };
  }, [data]);

  useEffect(() => {
    getSpeakers();
  }, [getSpeakers]);

  if (status === 'loading') {
    return <Loader fullScreen />;
  }

  if (status === 'error') {
    return (
      <Result
        title="Error request"
        extra={
          <Button type="primary" onClick={() => getSpeakers()}>
            Try again
          </Button>
        }
      />
    );
  }

  return (
    <div ref={container} className="speaker-embed">
      <Row gutter={[20, 30]}>
        {data?.map((item) => (
          <Col xs={12} md={6} xl={4}>
            <div
              className="speaker-embed__card"
              onClick={() => {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                return navigate(`/contact/${item.id}`);
              }}
            >
              <div className="speaker-embed__card_img">
                <img src={item.pic[0].url} alt={item.fullName} />
              </div>
              {/* <p className="speaker-embed__card_tag">Speaker</p> */}
              <p className="speaker-embed__card_title">{item.fullName}</p>
              <p className="speaker-embed__card_description">
                {item.title} {item.company && `| ${item.company}`}
              </p>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}
