import { CalendarOutlined, EnvironmentOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';

import useAnnouncements from '../hooks/useAnnouncements';
import useExhibitors from '../hooks/useExhibitors';
import { useSelector } from 'react-redux';
import { modifiedMomentjs } from '../utils/modifiedMomentjs';

export default function AnnouncementScreen() {
  const { data, error } = useSelector((state) => state.announcements);
  const { getAnnouncementById } = useAnnouncements();
  const { getSponsorsByTiers } = useExhibitors();
  const { recordId } = useParams();
  const navigate = useNavigate();

  function formatText(text) {
    const regex = /(\b(https?|http|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

    const formattedText = text.replace(regex, (match) => {
      const url = match.trim();
      return `a href="${url}">${url.replace(/[<>]/g, '')}</a`;
    });

    return <pre dangerouslySetInnerHTML={{ __html: formattedText }}></pre>;
  }

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  if (data.length === 0 || error || !getAnnouncementById(recordId)) return (window.location.href = '/');

  return (
    <div style={{ paddingBottom: 20 }}>
      <header className="header_event">
        <div className="header_event__back">
          <h2>
            <span className="cursor-pointer">
              <ArrowLeftOutlined onClick={() => navigate('/')} />
            </span>{' '}
          </h2>
        </div>

        <div className="header_event__information">
          <div className="header_event__information_date">
            <p>
              <span>
                <CalendarOutlined />
              </span>{' '}
              Posted {modifiedMomentjs(getAnnouncementById(recordId).created, 'MMMM DD, YYYY')}
            </p>
          </div>

          <div className="header_event__information_date">
            <p>
              <span>
                <EnvironmentOutlined />
              </span>{' '}
              {`Halifax Convention Centre, \n1650 Argyle St, Halifax, NS`}
            </p>
          </div>
        </div>
      </header>

      <div className="description">
        <div className="description__title">
          <p>{getAnnouncementById(recordId).title}</p>
        </div>

        <div className="description__content">
          <pre>
            {formatText(getAnnouncementById(recordId).content ? getAnnouncementById(recordId).content : 'No content.')}
          </pre>
        </div>
      </div>

      <div className="description">
        <div className="description__title">
          <p>Sponsored By:</p>
        </div>

        <div className="description__content">
          <h3>Platinum Sponsor:</h3>
          <div className="image-container">
            {getSponsorsByTiers('Platinum Sponsor').map((sponsor, index) => (
              <img
                key={index}
                src={sponsor?.img?.[0]?.url}
                alt={sponsor?.name}
                style={{ marginLeft: '20px', marginRight: '20px', width: '100%', maxWidth: '150px' }}
              />
            ))}
          </div>

          <br />

          <h3>Gold Sponsors:</h3>
          <div className="image-container">
            {getSponsorsByTiers('Gold Sponsor').map((sponsor, index) => (
              <img key={index} src={sponsor.img[0].url} alt={sponsor.name} className="sponsor-logo-announcement" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
