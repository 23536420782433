import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  data: [],
};

export const speakersSlice = createSlice({
  name: 'speakers',
  initialState,
  reducers: {
    IsLoadingSpeakers: (state, action) => {
      state.isLoading = action.payload;
      state.error = null;
    },
    addSpeakers: (state, action) => {
      state.data = action.payload;
    },
    addError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { IsLoadingSpeakers, addSpeakers, addError } = speakersSlice.actions;

export default speakersSlice.reducer;
