/* eslint-disable array-callback-return */
import { useNavigate, useParams } from 'react-router-dom';
import { Skeleton, Result, Button } from 'antd';
import useSpeakers from '../hooks/useSpeakers';
import { useSelector } from 'react-redux';
import SpeakerCard from './SpeakerCard';
import { useEffect } from 'react';

export default function Speakers({ speakers }) {
  const { isLoading, data, error } = useSelector((state) => state.speakers);
  const { getSpeakersAirtable, getSpeakerById } = useSpeakers();
  const navigate = useNavigate();
  const { recordId } = useParams();

  useEffect(() => {
    if (data.length === 0) getSpeakersAirtable();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speakers]);

  return (
    <div className="speakers">
      <div className="speakers__title">Speakers</div>

      {isLoading && (
        <div className="speakers__avatars">
          <div className="speaker-card">
            <div className="speaker-card__img" style={{ paddingLeft: 20 }}>
              <Skeleton.Avatar active={true} size={70} shape={'circle'} />
            </div>

            <div className="speaker-card__info">
              <div className="speaker-card__info_bio">
                <Skeleton paragraph={{ rows: 1 }} active />
              </div>
            </div>
          </div>
        </div>
      )}

      {!isLoading && data.length > 0 && (
        <>
          {!speakers?.length > 0 || !speakers ? (
            <div className="speakers__avatars_no-speakers">
              <p>No speakers registered.</p>
            </div>
          ) : (
            <>
              <div className="speakers__avatars">
                {speakers.map((speaker) => {
                  if (getSpeakerById(speaker)) {
                    return (
                      <SpeakerCard
                        data={getSpeakerById(speaker)}
                        onClick={() => navigate(`/${recordId}/${getSpeakerById(speaker).id}`)}
                      />
                    );
                  }
                })}
              </div>
            </>
          )}
        </>
      )}

      {error && (
        <Result
          title="Error request"
          extra={
            <Button type="primary" onClick={() => getSpeakersAirtable()}>
              Try again
            </Button>
          }
        />
      )}
    </div>
  );
}
