import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  data: [],
};

export const announcementsSlice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {
    IsLoadingAnnouncements: (state, action) => {
      state.isLoading = action.payload;
      state.error = null;
    },
    addAnnouncements: (state, action) => {
      state.data = action.payload;
    },
    addError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { IsLoadingAnnouncements, addAnnouncements, addError } = announcementsSlice.actions;

export default announcementsSlice.reducer;
