import { useNavigate } from 'react-router-dom';
import React from 'react';
import { modifiedMomentjs } from '../utils/modifiedMomentjs';

export default function SessionCard({ category, mapLink, sessionTrack, id, start, end, location, title }) {
  const navigate = useNavigate();

  const handleTag = (color, name) => {
    if (color)
      return (
        <div
          className="session-tag"
          style={{
            borderTop: `1.5px solid ${color}`,
          }}
        >
          {name && (
            <div
              className="session-tag_name"
              style={{
                backgroundColor: color,
              }}
            >
              <p>{name}</p>
            </div>
          )}
        </div>
      );
  };

  return (
    <div
      className={`card-schedule ${
        (category === 'Session' || category === 'Gala' || mapLink || '') && 'card-schedule--hoverable'
      }`}
      style={{
        cursor: (category === 'Session' || category === 'Gala' || mapLink) && 'pointer',
      }}
      onClick={() => {
        if (category === 'Session' || category === 'Gala') {
          navigate(`/${id}`);
        }

        if (mapLink) {
          window.open(mapLink, '_blank');
        }
      }}
    >
      {sessionTrack === 'Business & Operations' && (
        <div style={{ marginBottom: 18 }}>{handleTag('#F7E5CA', 'Business & Operations')}</div>
      )}

      {sessionTrack === 'Sales & Marketing' && (
        <div style={{ marginBottom: 18 }}>{handleTag('#DCFAE6', 'Sales & Marketing')}</div>
      )}

      {sessionTrack === 'Techniques & Innovations' && (
        <div style={{ marginBottom: 18 }}>{handleTag('#FEE4E2', 'Techniques & Innovations')}</div>
      )}

      {sessionTrack === 'Trends & Insights' && (
        <div style={{ marginBottom: 18 }}>{handleTag('#7dd3fc', 'Trends & Insights')}</div>
      )}

      {sessionTrack === 'Quality Assurance' && (
        <div style={{ marginBottom: 18 }}>{handleTag('#d8b4fe', 'Quality Assurance')}</div>
      )}

      {sessionTrack === 'Environmental Practices' && (
        <div style={{ marginBottom: 18 }}>{handleTag('#CFF9FE', 'Environmental Practices')}</div>
      )}

      {!sessionTrack && <div style={{ marginBottom: 18 }}>{handleTag('#ddd')}</div>}

      <div className="card-schedule__information">
        <p className="card-schedule__information_time">
          {modifiedMomentjs(start, 'h:mm A')} - {modifiedMomentjs(end, 'h:mm A')}
        </p>

        <div className="card-schedule__information_location">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M8 8.66665C9.10457 8.66665 10 7.77122 10 6.66665C10 5.56208 9.10457 4.66665 8 4.66665C6.89544 4.66665 6 5.56208 6 6.66665C6 7.77122 6.89544 8.66665 8 8.66665Z"
              stroke="#667085"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 14.6666C10.6667 12 13.3333 9.61217 13.3333 6.66665C13.3333 3.72113 10.9455 1.33331 8 1.33331C5.05449 1.33331 2.66667 3.72113 2.66667 6.66665C2.66667 9.61217 5.33334 12 8 14.6666Z"
              stroke="#667085"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p>{location}</p>
        </div>

        <p className="card-schedule__information_title">
          {category === 'Session' ? <span>{title || 'Unannounced Session'}</span> : title}
        </p>
      </div>
    </div>
  );
}
