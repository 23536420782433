export const SPONSOR_COLORS = {
  'Platinum Sponsor': '#667085',
  'Gold Sponsor': '#EAAA08',
  'Bronze Sponsor': '#CD7F32',
  'Silver Sponsor': '#808080',
  'Party Sponsor': '#E51937',
  'Swag Sponsor': '#FF6B6B',
  Exhibitor: '#4C9A2A',
  'Food Sponsor': '#FFA500',
  'Hospitality Sponsor': '#E2725B',
  'Media Sponsor': '#002D72',
  'Drink Sponsor': '#1877F2',
};
