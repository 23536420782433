import ScheduleFilterSlideover from '../components/ScheduleFilterSlideover';
import React, { useState, useEffect, useRef } from 'react';
import ScheduleTab from '../components/ScheduleTab';
import useSchedule from '../hooks/useSchedule';
import Tag from '../components/Tag';
import { modifiedMomentjs } from '../utils/modifiedMomentjs';

export default function ScheduleEmbedScreen({ viewName }) {
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [dayFilter, setDayFilter] = useState(sessionStorage.getItem('lastDate') || '2024-05-25');
  const { getDays } = useSchedule();
  const container = useRef();

  const [selectedCategories, setSelectedCategories] = useState(
    sessionStorage.getItem('selectedCategories') ? JSON.parse(sessionStorage.getItem('selectedCategories')) : null,
  );

  const [selectedSessionTracks, setSelectedSessionTracks] = useState(
    sessionStorage.getItem('selectedSessionTracks')
      ? JSON.parse(sessionStorage.getItem('selectedSessionTracks'))
      : null,
  );

  useEffect(() => {
    sessionStorage.setItem('lastDate', dayFilter);
  }, [dayFilter]);

  const sendMessage = () => {
    const height = container?.current?.scrollHeight;

    window.parent.postMessage({ height }, '*');
  };

  useEffect(() => {
    sendMessage();

    window.addEventListener('resize', sendMessage);

    return () => {
      window.removeEventListener('resize', sendMessage);
    };
  }, [dayFilter, getDays]);

  useEffect(() => {
    sessionStorage.setItem('selectedCategories', JSON.stringify(selectedCategories));
  }, [selectedCategories]);

  useEffect(() => {
    sessionStorage.setItem('selectedSessionTracks', JSON.stringify(selectedSessionTracks));
  }, [selectedSessionTracks]);

  return (
    <div ref={container}>
      <div
        style={{
          padding: '20px 22px 20px 22px',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'flex-end',
          position: 'sticky',
          flexDirection: 'column',
          top: 0,
          right: 10,
          background: '#f5f7f9',
          width: '100%',
          zIndex: 1000,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          {getDays() && (
            <>
              <div className="days-filter">
                {getDays().format.map((item, index) => (
                  <div
                    onClick={() => {
                      setDayFilter(item);

                      setTimeout(() => {
                        sendMessage();
                      }, 100);
                    }}
                    className={item === dayFilter && 'days-filter--selected'}
                  >
                    {getDays().days[index]}
                  </div>
                ))}
              </div>

              {getDays()?.days.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => setIsOpenFilters(true)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                      stroke="#475467"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="filter-items">
        {selectedCategories &&
          selectedCategories.length > 0 &&
          selectedCategories.map((item) => (
            <Tag
              closable
              onClose={() => setSelectedCategories(selectedCategories.filter((category) => category !== item))}
            >
              {item}
            </Tag>
          ))}
        {selectedSessionTracks &&
          selectedSessionTracks.length > 0 &&
          selectedSessionTracks.map((item) => (
            <Tag
              closable
              onClose={() =>
                setSelectedSessionTracks(selectedSessionTracks.filter((sessionTrack) => sessionTrack !== item))
              }
            >
              {item}
            </Tag>
          ))}
      </div>

      <ScheduleTab
        viewName={viewName}
        dayFilter={dayFilter}
        categoryFilter={selectedCategories}
        selectedCategories={selectedCategories}
        sessionTrackFilter={selectedSessionTracks}
      />

      <ScheduleFilterSlideover
        isOpen={isOpenFilters}
        selectedSessionTracks={selectedSessionTracks}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        setSelectedSessionTracks={setSelectedSessionTracks}
        onClose={() => setIsOpenFilters(false)}
      ></ScheduleFilterSlideover>
    </div>
  );
}
