import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React from 'react';

export default function SpeakerCard({ data, onClick }) {
  return (
    <div className="speaker-card" onClick={onClick}>
      <div className="speaker-card__img">
        {data?.pic ? (
          <img src={data.pic[0].url} alt="img" />
        ) : (
          <Avatar shape="circle" size={40} icon={<UserOutlined />} />
        )}
      </div>

      <div className="speaker-card__info">
        <div className="speaker-card__info_name">
          {data?.firstName} {data?.lastName}
        </div>

        <div className="speaker-card__info_bio">
          <p>{data?.title}</p>
          <p>{data?.company}</p>
        </div>
      </div>
    </div>
  );
}
