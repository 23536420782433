import { Spin } from 'antd';
import { LoadingIcon } from './LoadingIcon';

export default function Loader({ fullScreen }) {
  return (
    <div className={`${fullScreen ? 'loading-fullscreen' : 'loading'}`}>
      <Spin size="large" indicator={LoadingIcon} />
    </div>
  );
}
