import { Button, Empty, Result } from 'antd';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import useExhibitors from '../hooks/useExhibitors';
import ExhibitorCard from './ExhibitorCard';
import Loader from './Loader';
import { useNavigate } from 'react-router-dom';

export default function ExhibitorsTab() {
  const { isLoading, data, error } = useSelector((state) => state.exhibitors);
  const { getExhibitorsAirtable } = useExhibitors();
  const navigate = useNavigate();

  const filteredData = (data) => {
    if (!data || data.length === 0) return [];

    return data.filter(
      (item) => item.sponsorshipTiers && item.booths && item.boothNumbers && item.img && item.img.length > 0,
    );
  };

  useEffect(() => {
    if (data.length === 0) getExhibitorsAirtable();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Result
        title="Error request"
        extra={
          <Button type="primary" onClick={() => getExhibitorsAirtable()}>
            Try again
          </Button>
        }
      />
    );
  }

  if (data.length > 0) {
    return (
      <div
        style={{
          padding: 24,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        {filteredData(data).map((item, index) => (
          <ExhibitorCard
            onClick={() => navigate(`/exhibitors-embed/sponsor/${item.id}`)}
            key={index}
            img={item.img[0].url}
            sponsorshipTiers={item.sponsorshipTiers[0]}
            name={item?.name}
            booths={item?.boothNumbers}
          />
        ))}
      </div>
    );
  }

  return (
    <div className="space-section">
      <Empty />
    </div>
  );
}
