import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Speakers from '../components/Speakers';
import { CBACSponsors } from '../airtable';
import Loader from '../components/Loader';
import { Button, Result } from 'antd';
import { modifiedMomentjs } from '../utils/modifiedMomentjs';

export default function EventScreen() {
  const { recordId } = useParams();
  const navigate = useNavigate();
  const container = useRef();

  const [status, setStatus] = useState('loading');
  const [data, setData] = useState(null);

  const getEventData = useCallback(async () => {
    setStatus('loading');

    CBACSponsors('Schedule')
      .find(recordId)
      .then((record) => {
        setData({
          id: record.id,
          events: record.get('Events'),
          title: record.get('Title'),
          day: record.get('Day'),
          start: record.get('Start'),
          end: record.get('End'),
          location: record.get('Location'),
          roomNames: record.get('Room Names'),
          notes: record.get('Notes'),
          description: record.get('Description'),
          speakers: record.get('Speakers'),
          sponsorsExhibitors: record.get('Sponsors/Exhibitors (from Speakers)'),
          category: record.get('Category'),
          mapLink: record.get('Map Link'),
          sessionTrack: record.get('Track'),
          hidden: record.get('Hidden from Schedule'),
        });
        setStatus('success');
      })
      .catch(() => {
        setStatus('error');
      });
  }, [recordId]);

  const sendMessage = () => {
    const height = container?.current?.scrollHeight;

    window.parent.postMessage({ height }, '*');
  };

  useEffect(() => {
    sendMessage();

    window.addEventListener('resize', sendMessage);

    return () => {
      window.removeEventListener('resize', sendMessage);
    };
  }, [data]);

  useEffect(() => {
    getEventData();
  }, [getEventData]);

  if (status === 'loading') {
    return <Loader fullScreen />;
  }

  if (status === 'error') {
    return (
      <Result
        title="Error request"
        extra={
          <Button type="primary" onClick={() => getEventData()}>
            Try again
          </Button>
        }
      />
    );
  }

  return (
    <div ref={container}>
      <header className="header_event">
        <div className="header_event__back">
          <h2>
            <span className="cursor-pointer">
              <ArrowLeftOutlined onClick={() => navigate(-1)} />
            </span>
          </h2>
        </div>

        <div className="header_event__information">
          <p className="header_event__information_title">{data?.title}</p>
          <p className="header_event__information_date">
            {modifiedMomentjs(data?.start, 'MMM D, YYYY h:mm A')} - {modifiedMomentjs(data?.end, 'h:mm A')}
          </p>

          <div className="header_event__information_location">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M7.99996 8.66665C9.10453 8.66665 9.99996 7.77122 9.99996 6.66665C9.99996 5.56208 9.10453 4.66665 7.99996 4.66665C6.89539 4.66665 5.99996 5.56208 5.99996 6.66665C5.99996 7.77122 6.89539 8.66665 7.99996 8.66665Z"
                stroke="#0086C9"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.99996 14.6666C10.6666 12 13.3333 9.61217 13.3333 6.66665C13.3333 3.72113 10.9455 1.33331 7.99996 1.33331C5.05444 1.33331 2.66663 3.72113 2.66663 6.66665C2.66663 9.61217 5.33329 12 7.99996 14.6666Z"
                stroke="#0086C9"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <p>{data?.location}</p>
          </div>
        </div>
      </header>

      <div className="description">
        <div className="description__title">Session overview</div>

        <div className="description__content">
          <p>{data?.description && data?.description !== '\n' ? data?.description : 'No description.'}</p>
        </div>
      </div>

      <Speakers speakers={data?.speakers} />
    </div>
  );
}
