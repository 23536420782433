import { IsLoadingSpeakers, addError, addSpeakers } from '../store/speakersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { CBACSponsors } from '../airtable';

export default function useSpeakers() {
  const { data } = useSelector((state) => state.speakers);
  const dispatch = useDispatch();

  const getSpeakersAirtable = () => {
    dispatch(IsLoadingSpeakers(true));
    const data = [];

    CBACSponsors('Contacts')
      .select({
        view: 'Grid view',
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            const recordData = {
              id: record.id,
              firstName: record.get('First Name'),
              lastName: record.get('Last Name'),
              fullName: record.get('Full Name'),
              pic: record.get('Pic'),
              bio: record.get('Bio'),
              events: record.get('Schedule'),
              title: record.get('Title/ Position'),
              company: record.get('Company'),
              description: record.get('Description'),
            };

            data.push(recordData);
          });

          fetchNextPage();
        },
        function done(err) {
          if (err) {
            dispatch(IsLoadingSpeakers(false));
            dispatch(addError(err.message));
            return;
          }

          dispatch(addSpeakers(data));
          dispatch(IsLoadingSpeakers(false));
          return;
        },
      );
  };

  const getSpeakersByEvent = (eventName, day) => {
    const dataToFind = `${eventName} - ${day}`;

    return data.filter((item) => {
      if (item.events) {
        return item.events.includes(dataToFind);
      } else {
        return false;
      }
    });
  };

  const getSpeakerById = (id) => {
    return data.find((item) => item.id === id);
  };

  return { getSpeakersAirtable, getSpeakersByEvent, getSpeakerById };
}
