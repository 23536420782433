import { Image, Typography } from 'antd';
import map1 from '../assets/Convention Floor Plan.jpg';
import map2 from '../assets/Argyle Level Floorplan.png';
import map3 from '../assets/Ballroom Floor Plan.jpg';

const { Title } = Typography;

export default function MapTab() {
  return (
    <div style={{ margin: '24px', paddingTop: '10px', paddingBottom: '10px' }}>
      <Title level={2}>Convention Floor Plan</Title>
      <Image src={map1} alt="map" style={{ width: '100%' }} />
      <br />
      <br />
      <Title level={2}>Argyle Level Floor Plan</Title>
      <Image src={map2} alt="map" style={{ width: '100%' }} />
      <br />
      <br />
      <Title level={2}>Ballroom Level Floor Plan</Title>
      <Image src={map3} alt="map" style={{ width: '100%' }} />
    </div>
  );
}
